import { reactive } from 'vue'
import { toLocal } from './storages'

const state = reactive({
	token: null,
	device: null,
	companies: [],
	threshold: 0,
	reload: 0,
	backSubdomain: process.env.VUE_APP_API_SUBDOMAIN,
	streamSubdomain: process.env.VUE_APP_STREAM_SUBDOMAIN,
	facingMode: 'user',
})

const actions = {
	setToken(token) {
		state.token = token
	},

	setDevice(device) {
		state.device = device
	},

	setCompanies(companies) {
		state.companies = companies
	},

	setReload(reload) {
		state.reload = reload
	},

	setBackSubdomain(back) {
		if (!back) {
			return;
		}

		state.backSubdomain = back
	},

	setStreamSubdomain(stream) {
		if (!stream) {
			return;
		}

		state.streamSubdomain = stream
	},

	setThreshold(threshold) {
		state.threshold = threshold;
	},

	setFacingMode(mode) {
		state.facingMode = mode;
	}
}

toLocal(state, {
	// namespace: 'capture',
	attrs: [
		'token', 
		'companies', 
		'device', 
		'threshold',
		'reload',
		'backSubdomain',
		'streamSubdomain',
		'facingMode',
	],
})

export { state, actions }
